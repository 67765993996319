<template>
    <div class="popup" :class="{ 'popup-show': popupRouletteShow }">
        <div class="popup__wrapper">
            <confetti-explosion v-if="visibleConfetti"></confetti-explosion>
            <div class="popup__content">
                <div class="cart__top top-cart">
                    <div></div>
                    <button type="button" class="popup__close _icon-close" @click="popupRouletteShow = 0"></button>
                </div>
                <div class="popup__roulette-content">
                    <div class="popup__roulette-col">
                        <div class="popup__roulette-roulette">
                            <roulette-products-component :products="products" ref="RouletteProductsComponent" @wheelStopped="handleWheelStopped"></roulette-products-component>
                        </div>
                    </div>
                    <div class="popup__roulette-col" v-if="!discountReceived">
                        <div class="popup__roulette-header">
                            <h2>Вигравай прикрасу!</h2>
                            <p>Крути колесо фортуни та отримай призи! Для участі введи свої данні:</p>
                        </div>
                        <div class="popup__roulette-form">
                            <input class="input" name="firstName" type="text" v-model="fieldfirstName" placeholder="Ваше ім’я *" required />
                            <input class="input" name="phone" v-mask="'+38 (###) ###-##-##'" type="text" v-model="fieldPhone" placeholder="Телефон *" required />
                            <input class="input" :class="{ 'input-error': !emailValid }" name="email" type="email" v-model="fieldEmail" placeholder="E-mail *" required />
                            <button class="button" @click="launchWheel">Крутити</button>
                            <Chekbox isChekedDefault="true"
                                ><span>Я погоджуюся з <router-link :to="{ name: 'Offerta' }" target="_blank">договором оферти і політикою конфіденційності</router-link></span></Chekbox
                            >
                        </div>
                    </div>

                    <div class="popup__roulette-col" v-if="discountReceived">
                        <div class="popup__roulette-header">
                            <h3>
                                Вітаємо! <br />Ви виграли прикрасу:<br /><b>{{ productName }} </b><br />
                                <img :src="'https://ig.le-perle.com.ua' + productImage" alt="" width="100" />
                            </h3>
                            <button class="button" @click="popupRouletteShow = 0">
                                <span> Додати в кошик </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RouletteProductsComponent from "./RouletteProductsComponent.vue";
import Chekbox from "./Chekbox";
import { useToast } from "vue-toast-notification";
import axios from "axios";
import ConfettiExplosion from "vue-confetti-explosion";
import { mask } from "vue-the-mask";
import { ref } from "vue";
import { useStore } from "vuex";

export default {
    setup() {
        const toast = useToast();
        const store = useStore();

        const products = ref([
            {
                id: 356,
                image_url: "/uploads/6580803aea1e7.webp",
                name: 'Сережки "Трансформери"',
                slug: "serezhki-transformeri-356",
            },
            {
                id: 252,
                image_url: "/uploads/6572612df40f7.webp",
                name: 'Каблучка "Love me Gold"',
                slug: "kabluchka-love-me-gold-252",
            },
            {
                id: 363,
                image_url: "/uploads/6580796623044.webp",
                name: "Сережки “Mystic”",
                slug: "serezhki-mystic-363",
            },
            {
                id: 463,
                image_url: "/uploads/65807c8c43279.webp",
                name: 'Сережки "Fortune"',
                slug: "serezhki-fortune-463",
            },
            {
                id: 437,
                image_url: "/uploads/65729087d9de3.webp",
                name: 'Сережки "Steel Колосок"',
                slug: "serezhki-steel-kolosok-437",
            },
            {
                id: 436,
                image_url: "/uploads/657268f3d5d68.webp",
                name: 'Сережки "Колосок"',
                slug: "serezhki-kolosok-436",
            },
            {
                id: 247,
                image_url: "/uploads/657261798666c.webp",
                name: 'Каблучка "G Corset"',
                slug: "kabluchka-g-corset-247",
            },
            {
                id: 463,
                image_url: "/uploads/65807c8c43279.webp",
                name: 'Сережки "Fortune"',
                slug: "serezhki-fortune-463",
            },
        ]);

        const addToCart = (product, quantity) => {
            let cart = localStorage.getItem("cart");
            if (cart) {
                cart = JSON.parse(cart);
            } else {
                cart = [];
            }

            // Проверяем, есть ли товар с параметром gift: true в корзине
            const giftProductExists = cart.some((item) => item.gift === true);

            if (giftProductExists) {
                return;
            }

            const itemToAdd = {
                id: product.id,
                product_id: product.id,
                variant_id: null,
                quantity: quantity,
                percent: 0,
                price: 0.0,
                price_origin: 0.0,
                product: {
                    id: product.id,
                    slug: product.slug,
                    name: product.name,
                    price: 0.0,
                    price_origin: 0.0,
                    image: product.image_url,
                    downloads: [{ path: product.image_url }],
                },
                variant: [],
                is_set: 0,
                gift: true,
            };

            const existingProductIndex = cart.findIndex((item) => item.id === itemToAdd.id);
            if (existingProductIndex >= 0) {
                cart[existingProductIndex].quantity++;
            } else {
                cart.push(itemToAdd);
            }
            // Обновляем корзину через Vuex
            store.dispatch("updateCart", cart);

            // Диспатчим событие без добавления слушателя
            document.dispatchEvent(new CustomEvent("cart-updated"));
        };

        return { toast, products, addToCart };
    },
    name: "RouletteProductsPopup",
    components: { RouletteProductsComponent, Chekbox, ConfettiExplosion },
    directives: { mask },
    data() {
        return {
            apiUrl: process.env.VUE_APP_PROD_API_URL,
            popupRouletteShow: 0,
            RouletteProductsComponent: null, // Добавьте это
            fieldfirstName: "",
            fieldPhone: "",
            fieldEmail: "",
            submitForm: false,
            discountReceived: false,
            productName: "",
            productImage: "",
            visibleConfetti: false,
            emailValid: true,
        };
    },
    methods: {
        validateEmail(email) {
            const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            this.emailValid = re.test(email);
        },
        launchWheel() {
            this.validateEmail(this.fieldEmail); // Вызываем валидацию email

            if (this.submitForm) return;

            if (this.fieldfirstName.length > 0 && this.fieldPhone.length > 0 && this.fieldEmail.length > 0 && !this.submitForm) {
                if (!this.emailValid) {
                    this.toast.error("Введіть коректну електронну адресу.");
                    return;
                }

                this.isVisibleContacts = !this.isVisibleContacts;
                this.$refs.RouletteProductsComponent.spin();
                this.submitForm = true;
            } else {
                this.toast.error("Будь ласка, заповніть всі обов'язкові поля.");
            }
        },
        getUTMData() {
            const cookies = document.cookie.split(";");
            let utmData = {};

            for (let i = 0; i < cookies.length; i++) {
                const [name, value] = cookies[i].trim().split("=");
                if (name === "utm") {
                    utmData = JSON.parse(decodeURIComponent(value));
                    break;
                }
            }

            return utmData;
        },
        handleWheelStopped(text) {
            const utmData = this.getUTMData();
            let formData = {
                precent: text,
                email: this.fieldEmail,
                phone: this.fieldPhone,
                name: this.fieldfirstName,
                withCredentials: true,
            };

            Object.keys(utmData).forEach((key) => {
                formData[key] = utmData[key];
            });

            axios
                .post(this.apiUrl + "promocode/generate", formData)
                .then((response) => {
                    this.visibleConfetti = true;
                    this.discountPromocode = response.data.code;
                    this.productName = text.name;
                    this.productImage = text.image_url;
                    this.discountReceived = true;
                    this.setCookie("discountReceived", true, 1);

                    // Вызов addToCart
                    this.addToCart(text, 1);
                })
                .catch((error) => {
                    console.error("Ошибка при запросе:", error);
                });
        },
        openPopup() {
            const popupShown = this.getCookie("popupShown");
            const discountReceived = this.getCookie("discountReceived");

            if (popupShown || discountReceived) return;

            setTimeout(() => {
                this.popupRouletteShow = 1;
                this.setCookie("popupShown", true, 1); // Устанавливаем куки на 1 день
            }, 30000); // 30 секунд
        },
        setCookie(name, value, days) {
            const expires = new Date(Date.now() + days * 864e5).toUTCString();
            document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return decodeURIComponent(parts.pop().split(";").shift());
            return null;
        },
    },
    mounted() {
        this.openPopup();
    },
};
</script>
<style scoped>
.popup__roulette-content {
    display: flex;
    align-items: center;
    padding: 0 10px;
    padding-top: 40px;
    padding-bottom: 40px;
}

@media (max-width: 768px) {
    .popup__roulette-content {
        flex-direction: column;
        row-gap: 20px;
    }
    .popup__roulette-roulette {
        display: flex;
        justify-content: center;
    }
}

.popup__roulette-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 25px;
}

.popup__roulette-header h2 {
    font-size: 30px;
    font-weight: bold;
}
.popup__roulette-header h3 {
    font-size: 30px;
    font-weight: 300;
}

.popup__roulette-header p {
    font-size: 16px;
}

.popup__roulette-header img {
    margin: 10px 0;
}

.popup__roulette-form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.popup__roulette-form .input {
    border-radius: 6px !important;
    border: 1px solid #000000bd;
}
.popup__roulette-form .button {
    width: 100%;
}
.popup__roulette-col {
    width: 100%;
}
.input-error {
    border-color: red !important;
}
</style>
