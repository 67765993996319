<template>
    <div class="cart order__cart">
        <div class="order__cart-item" :class="{ active: activeIndex === 1 }" style="margin-bottom: 20px">
            <div class="order__cart-header" @click="toggleAccordion(1)">Ваше замовлення</div>
            <transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                <div v-if="activeIndex === 1" ref="content" class="order__cart-content">
                    <div class="cart__overflow">
                        <div class="cart__items" v-for="item in cart" :key="item.id">
                            <div class="cart__item item-cart">
                                <div v-if="item?.gift && getRemainingAmount() !== 0" class="cart-item-disabled"></div>
                                <div v-if="item?.gift && getRemainingAmount() !== 0" class="cart__progress-block cart-progress-block">
                                    <div class="cart__progress"><span :style="{ width: getProgressWidth() }"></span></div>
                                    <span>До отримання подарунку залишилось:</span>
                                    <span>{{ getRemainingAmount() }} грн.</span>
                                </div>
                                <router-link :to="{ name: 'Product', params: { slug: item.product.slug } }" class="item-cart__image-ibg"><img :src="getImageProduct(item)" alt="" /></router-link>

                                <div class="item-cart__content">
                                    <div class="item-cart__info">
                                        <div class="item-cart__title">
                                            <router-link :to="{ name: 'Product', params: { slug: item.product.slug } }">{{ item.product.name.ua ?? item.product.name }}</router-link>
                                        </div>
                                        <div class="item-cart__material" v-for="optionValues in filterOptionValues(item.variant.option_values)" :key="optionValues.id">
                                            {{ getOptionLabel(optionValues) }}<span v-if="item.product.category">{{ transformWordToSingular(item.product.category.title.ua) }}</span
                                            >: {{ optionValues.value }}
                                        </div>
                                        <Counter v-if="!item?.gift" class="item-cart__counter counter_small" :productIdLimited="getProductsLimitAdditionalsCart()" :productId="item.id" :countProducts="countProductsCart()" @update:modelValue="(newQuantity) => updateQuantity(item, newQuantity)" />
                                    </div>
                                    <div class="item-cart__price">
                                        <div class="item-cart__number">
                                            <!-- <span :v-if="item.variant.old_price || item.product.old_price" style="opacity: 0.5; text-decoration: line-through; transform: scale(0.9)"> {{ item.variant.old_price ? item.variant.old_price : item.product.old_price }} </span> -->
                                            <span v-if="(item.variant.old_price && item.variant.old_price !== null && item.variant.old_price !== '0.00') || (item.product.old_price && item.product.old_price !== null && item.product.old_price !== '0.00')" style="opacity: 0.5; text-decoration: line-through; transform: scale(0.9)"> {{ calculateOldPrice(item) }} грн. </span>
                                            <!-- <span v-if="!excludeProductIds(item.product.id) && (item.variant.old_price || item.product.old_price)" style="opacity: 0.5; text-decoration: line-through; transform: scale(0.9)"> {{ item.variant.old_price ? item.variant.old_price : item.product.old_price }} грн. </span> -->
                                            <!-- Для скидки -10 и 15% -->
                                            <span> {{ calculatePrice(item) }} грн. </span>
                                            <!-- END Для скидки -10 и 15% -->
                                            <!-- Для одиночкой скидки -->
                                            <!-- <span> {{ item.price || item.variant.price || item.product.price }} грн. </span> -->
                                            <!-- <span> {{ !excludeProductIds(item.product.id) ? (item.variant.price ? item.variant.price * 0.95 : item.product.price * 0.95) : item.variant.price ? item.variant.price : item.product.price }} грн. </span> -->
                                            <!-- END Для одиночкой скидки -->
                                            <!-- <span> {{ item.variant.price ? item.variant.price : item.product.price }} грн. </span> -->
                                            <!-- <span v-if="item.free" style="color: red; transform: scale(0.8)">1 шт / 0 грн</span> -->
                                        </div>
                                        <button class="item-cart__button _icon-delete" @click="removeItem(item.id)"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cart__order order-cart">
                        <div class="order-cart__title">Ваше замовлення</div>
                        <div class="order-cart__line">
                            <span>Сума замовлення:</span>
                            <span>{{ $toFixed(getTotalBeforeDiscount(), 2) }} грн.</span>
                        </div>
                        <div class="order-cart__line">
                            <span>Знижка:</span>
                            <span>{{ getDiscount() }} грн.</span>
                        </div>
                        <div class="order-cart__all">
                            <span>Разом:</span>
                            <span>{{ getTotalAfterDiscount() }} грн.</span>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <div class="order__cart-item" v-if="cart && cart.length !== 0">
            <div class="cart__overflow">
                <div class="cart__title">Подарункове пакування</div>

                <div v-for="item in productPackage" :key="item.id" class="cart__items">
                    <div class="cart__item item-cart">
                        <a href="#" class="item-cart__image-ibg">
                            <img :src="$uploadUrl + item.downloads[0].path" alt="" />
                        </a>
                        <div class="item-cart__content">
                            <div class="item-cart__info">
                                <div class="item-cart__title">
                                    <a href="#">{{ item.name }}</a>
                                </div>

                                <div v-if="item.variants[0]" class="item-cart__material">Розмір: {{ item.variants[0].option_values[0].value }}</div>
                            </div>
                            <div class="item-cart__price item-cart__price-package">
                                <div class="item-cart__number">{{ item.price }} грн.</div>
                                <Counter class="item-cart__counter counter_small" v-model="item.quantity" />
                            </div>
                            <button class="item-cart__add button" @click="addToCart(item, item.quantity)">Додати</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* global dataLayer */
/* global KTracking */

import { useStore } from "vuex";
import Counter from "./Counter.vue";
import { ref, onMounted, watchEffect, computed, getCurrentInstance } from "vue";
import "vue-toast-notification/dist/theme-bootstrap.css";
import { useToast } from "vue-toast-notification";
import axios from "axios";

export default {
    name: "CartOrderApp",
    props: ["discountData", "discountType"],
    components: {
        Counter,
    },
    data() {
        return {
            activeIndex: 1,
        };
    },
    setup(props) {
        const cart = ref([]);
        const limitedProductsIds = ref([]);
        const store = useStore();
        const productPackage = computed(() => store.getters.getProductPackage);
        const productPackageIds = computed(() => store.getters.getProductPackageIds);
        const toast = useToast();

        const totalPrice = computed(() => store.getters.totalPriceCart);
        const priceTotalActionOneToOne = ref(0);
        const component = getCurrentInstance(); // Получение доступа к экземпляру компонента
        const apiUrl = process.env.VUE_APP_PROD_API_URL;

        const getCookie = component.appContext.config.globalProperties.$getCookie;
        const getEventId = component.appContext.config.globalProperties.$getEventId;
        const toFixed = component.appContext.config.globalProperties.$toFixed;

        const event_id = getEventId();
        const external_id = localStorage.getItem("external_id");

        const discountPrecentDefaut = component.appContext.config.globalProperties.$discountPrecentDefaut;
        const discountPrecentAdditional = component.appContext.config.globalProperties.$discountPrecentAdditional;
        const freeDeliveryThreshold = 2500;

        // const priceTotalDiscountActionOneToOne = ref(0);

        // cart.value = localStorage.getItem("cart");
        watchEffect(() => {
            // let storedCart = localStorage.getItem("cart");
            // if (storedCart) {
            //     cart.value = JSON.parse(storedCart);
            //     const totalQuantity = cart.value.reduce((total, item) => total + item.quantity, 0);
            //     if (totalQuantity >= 3) {
            //         // Поиск самого дешевого товара
            //         let cheapestItem = null;
            //         let cheapestPrice = Number.MAX_VALUE;
            //         cart.value.forEach((item) => {
            //             const price = parseFloat(item.product.price);
            //             if (price < cheapestPrice) {
            //                 cheapestPrice = price;
            //                 cheapestItem = item;
            //             }
            //         });
            //         // Применение скидки
            //         if (cheapestItem) {
            //             cheapestItem.free = 1; // Добавляем параметр free: 1
            //             priceTotalActionOneToOne.value = cheapestItem.variant.old_price ?? cheapestItem.product.old_price ?? cheapestItem.variant.price ?? cheapestItem.product.price;
            //             priceTotalDiscountActionOneToOne.value = cheapestItem.variant.old_price ?? cheapestItem.product.old_price ?? cheapestItem.variant.price ?? cheapestItem.product.price;
            //         }
            //     } else {
            //         // Удаление параметра free, если оваров меньше 3
            //         cart.value.forEach((item) => {
            //             if (item.free) {
            //                 priceTotalActionOneToOne.value = 0;
            //                 priceTotalDiscountActionOneToOne.value = 0;
            //                 delete item.free;
            //             }
            //         });
            //     }
            // }
        });

        const updateCart = () => {
            let storedCart = localStorage.getItem("cart");
            if (storedCart) {
                cart.value = JSON.parse(storedCart);
            }
        };

        const filterOptionValues = (optionValues) => {
            if (optionValues && optionValues.length) {
                return optionValues.filter((option) => option.value);
            }
            return []; // Возвращаем пустой массив, если optionValues undefined или пуст
        };

        const removeItem = (itemId) => {
            cart.value = cart.value.filter((item) => item.id !== itemId);
            localStorage.setItem("cart", JSON.stringify(cart.value));
        };

        const getItemPriceBeforeDiscount = (item) => {
            let price = 0;
            // Проверяем наличие старой цены в варианте и основном продукте
            if (item.is_set === 1) {
                Object.values(item.sets).forEach((itemSet) => {
                    price += parseFloat(itemSet.old_price);
                });
                return price;
            }
            if (item.variant) {
                if (item.variant.old_price != null) {
                    price = parseFloat(item.variant.old_price);
                } else {
                    price = parseFloat(item.product.old_price || item.variant.price || item.product.price);
                }
            } else {
                price = parseFloat(item.product.old_price || item.product.price);
            }

            return price;
        };

        const getTotalBeforeDiscount = () => {
            return cart.value.reduce((total, item) => {
                return total + item.quantity * getItemPriceBeforeDiscount(item);
            }, 0);
        };

        const getRemainingAmount = () => {
            const total = getTotalAfterDiscount();
            return total >= 1000 ? 0 : Math.round(1000 - total);
        };

        const getProgressWidth = () => {
            const total = getTotalAfterDiscount();
            return total >= freeDeliveryThreshold ? "100%" : `${(total / freeDeliveryThreshold) * 100}%`;
        };

        // const getTotalOriginPrice = () => {
        //     return cart.value.reduce((total, item) => {
        //         return total + item.quantity * (item.variant.price_origin ?? item.product.price_origin);
        //     }, 0);
        // };

        // const getSummExcludeProducts = () => {
        //     return cart.value.reduce((total, item) => {
        //         if (excludeProductIds(item.id)) {
        //             return total + item.quantity * getItemPriceBeforeDiscount(item);
        //         }
        //         return 0;
        //     }, 0);
        // };

        const getItemPriceAfterDiscount = (item) => {
            if (item.is_set === 1) {
                return item.price;
            }
            if (item.price_origin) {
                return item.price_origin; // Если есть вариация и цена вариации, то используем её
            }
            if (item.variant && item.variant.price_origin) {
                return item.variant.price_origin; // Если есть вариация и цена вариации, то используем её
            }
            return item.product.price_origin; // Иначе используем цену продукта
        };

        // const getTotalAfterDiscount = () => {
        //     // const totalBeforePromo = cart.value.reduce((total, item) => {
        //     //     return total + item.quantity * getItemPriceAfterDiscount(item);
        //     // }, 0);

        //     // const countProductsCart = cart.value.reduce((total, item) => {
        //     //     return total + item.quantity;
        //     // }, 0);

        //     let discountCountProducts = 0;

        //     let sumDiscountCounts = 0;
        //     // скидки от количества продуктов
        //     // if (countProductsCart >= 2) {
        //     //     discountCountProducts = getTotalBeforeDiscount() - priceTotalActionOneToOne.value;
        //     //     // sumDiscountCounts = discountCountProducts * 0.81;
        //     //     sumDiscountCounts = discountCountProducts;
        //     //     // sumDiscountCounts = getTotalBeforeDiscount() - discountCountProducts;
        //     //     // sumDiscountCounts = 0;
        //     // } else if (countProductsCart === 1) {
        //     //     discountCountProducts = getTotalBeforeDiscount() - priceTotalActionOneToOne.value;
        //     //     sumDiscountCounts = discountCountProducts * 0.9;
        //     // } else {
        //     //     discountCountProducts = getTotalBeforeDiscount() - priceTotalActionOneToOne.value;
        //     //     // sumDiscountCounts = discountCountProducts * 0.9;
        //     //     sumDiscountCounts = discountCountProducts;
        //     // }
        //     //     sumDiscountCounts = discountCountProducts * 0.9;

        //     discountCountProducts = getTotalBeforeDiscount() - priceTotalActionOneToOne.value;
        //     sumDiscountCounts = discountCountProducts * 0.9;

        //     // Применяем скидку по промокоду
        //     // let totalAfterPromo = totalBeforePromo * (1 - props.discountData / 100);
        //     // totalAfterPromo = totalAfterPromo - sumDiscountCounts;
        //     // Округляем до двух знаков после запятой
        //     // totalAfterPromo = parseFloat(totalAfterPromo.toFixed(2));

        //     return parseFloat(sumDiscountCounts.toFixed(2));
        // };

        function countProductsCart() {
            return cart.value.reduce((total, item) => {
                if (!productPackageIds.value.includes(item.product.id)) {
                    return total + item.quantity;
                }
                return total;
            }, 0);
        }

        function getProductsLimitAdditionalsCart() {
            return cart.value.filter((item) => item.product.tags && item.product.tags.some((tag) => tag.value === "limit_additions_to_cart")).map((item) => item.product.id);
        }

        const getTotalAfterDiscount = () => {
            let totalAfterDiscount = 0;
            let countProducts = countProductsCart();

            // Перебираем все товары в корзине
            cart.value.forEach((item) => {
                let itemTotal = item.quantity * getItemPriceAfterDiscount(item);

                // Применяем скидку 10%, если id товара не равен 2
                if (!productPackageIds.value.includes(item.product.id) && countProducts < 2 && item.is_set === 0) {
                    itemTotal *= discountPrecentDefaut;
                } else if (!productPackageIds.value.includes(item.product.id) && countProducts >= 2 && item.is_set === 0) {
                    itemTotal *= discountPrecentAdditional;
                } else if (item.percent && item.is_set === 0) {
                    itemTotal *= (100 - item.percent) / 100;
                }

                // Добавляем стоимость товара (уже со скидкой, если она применяется) к итоговой сумме
                totalAfterDiscount += itemTotal;
            });

            let discountByPromoCode = 0;
            if (typeof props.discountData !== "undefined") {
                // discountByPromoCode = totalAfterDiscount * (props.discountData / 100);
                if (props.discountType == "PERCENT") {
                    discountByPromoCode = totalAfterDiscount * (props.discountData / 100);
                } else if (props.discountType == "AMOUNT") {
                    discountByPromoCode = props.discountData;
                }
                // discountByPromoCode = props.discountType == "PERCENT" ? getTotalBeforeDiscount() * (props.discountData / 100) : props.discountData;
            }

            totalAfterDiscount -= discountByPromoCode;

            // Возвращаем итоговую сумму, округленную до двух знаков после запятой
            return parseFloat(toFixed(totalAfterDiscount));
        };

        // const getDiscount = () => {
        //     // let discountByPromoCode = 0;
        //     // Если есть процент скидки
        //     if (typeof props.discountData !== "undefined") {
        //         // discountByPromoCode = getTotalBeforeDiscount() * (props.discountData / 100);
        //     }

        //     // const countProductsCart = cart.value.reduce((total, item) => {
        //     //     return total + item.quantity;
        //     // }, 0);

        //     let discountCountProducts = 0;

        //     let sumDiscountCounts = 0;
        //     // if (countProductsCart >= 2) {
        //     //     discountCountProducts = getTotalBeforeDiscount() - priceTotalActionOneToOne.value;
        //     //     // sumDiscountCounts = discountCountProducts * 0.81;
        //     //     sumDiscountCounts = discountCountProducts;
        //     // } else if (countProductsCart === 1) {
        //     //     discountCountProducts = getTotalBeforeDiscount() - priceTotalActionOneToOne.value;
        //     //     sumDiscountCounts = discountCountProducts * 0.9;
        //     // } else {
        //     //     discountCountProducts = getTotalBeforeDiscount() - priceTotalActionOneToOne.value;
        //     //     // sumDiscountCounts = discountCountProducts * 0.9;
        //     //     sumDiscountCounts = discountCountProducts;
        //     // }

        //     discountCountProducts = getTotalBeforeDiscount() - priceTotalActionOneToOne.value;
        //     sumDiscountCounts = discountCountProducts * 0.9;

        //     // let discountByPriceDifference = cart.value.reduce((discount, item) => {
        //     //     let oldPrice = 0;
        //     //     let currentPrice = 0;

        //     //     // Проверяем наличие цен в варианте и основном продукте
        //     //     if (item.variant && item.variant.length > 0) {
        //     //         oldPrice = parseFloat(item.variant[0].old_price || 0); // или 0, если нет old_price
        //     //         currentPrice = parseFloat(item.variant[0].price);
        //     //     } else {
        //     //         oldPrice = parseFloat(item.product.old_price || 0); // или 0, если нет old_price
        //     //         currentPrice = parseFloat(item.product.price);
        //     //     }

        //     //     // Если старая цена есть, то вычитаем текущую цену из старой и умножаем на количество
        //     //     if (oldPrice > 0) {
        //     //         return discount + (oldPrice - currentPrice) * item.quantity;
        //     //     }

        //     //     return discount; // Если нет старой цены, возвращаем накопленную скидку без изменений
        //     // }, 0);

        //     let result = getTotalBeforeDiscount() - sumDiscountCounts;
        //     result = Number(result.toFixed(2));
        //     console.log(getTotalBeforeDiscount());
        //     return result;
        // };

        const getDiscount = () => {
            let discountByPromoCode = 0;
            if (typeof props.discountData !== "undefined") {
                // discountByPromoCode = getTotalBeforeDiscount() * (props.discountData / 100);

                if (props.discountType == "PERCENT") {
                    discountByPromoCode = getTotalBeforeDiscount() * (props.discountData / 100);
                } else if (props.discountType == "AMOUNT") {
                    discountByPromoCode = props.discountData;
                }
            }
            let totalAfterDiscount = 0;
            let countProducts = countProductsCart();

            // Перебираем все товары в корзине и применяем скидку 10%, если это не товар с id == 2
            cart.value.forEach((item) => {
                let itemTotal = item.quantity * getItemPriceAfterDiscount(item);

                // Применяем скидку 10%, если id товара не равен 2
                if (!productPackageIds.value.includes(item.product.id) && countProducts < 2) {
                    // itemTotal *= 0.90;
                } else if (!productPackageIds.value.includes(item.product.id) && countProducts >= 2) {
                    itemTotal *= discountPrecentAdditional;
                }
                // if (!productPackageIds.value.includes(item.product.id)) {
                // itemTotal *= 0.95;
                // }
                if (!productPackageIds.value.includes(item.product.id)) {
                    // itemTotal *= 0.95;
                }

                totalAfterDiscount += itemTotal;
            });

            // let discountPromocode = (totalBeforeDiscount * props.discountData) / 100;
            // Вычисляем скидку как разницу между ценой до скидок и после
            let discount = getTotalBeforeDiscount() - (totalAfterDiscount - discountByPromoCode);

            // Округляем результат до двух знаков после запятой и возвращаем
            return Number(toFixed(discount));
        };

        const updateQuantity = (item, newQuantity) => {
            let updatedItem = { ...item, quantity: newQuantity };
            let index = cart.value.findIndex((i) => i.id === item.id);
            cart.value.splice(index, 1, updatedItem);
            localStorage.setItem("cart", JSON.stringify(cart.value));
        };

        const createDataLayerItems = (cartItems) => {
            return cartItems.map((item) => ({
                item_name: item.product.name,
                item_id: item.product.vendor_code,
                price: getItemPriceAfterDiscount(item),
                item_brand: "Le-perle",
                item_category: item.product.category?.title?.ua || (Array.isArray(item.product.category) && item.product.category.length > 0 ? item.product.category[0]?.title : "") || "",
                item_variant: item.variant.vendor_code ? item.variant.vendor_code : item.product.vendor_code,
                quantity: item.quantity,
            }));
        };

        const fbCheckout = () => {
            let num_items = cart.value.reduce((total, item) => total + item.quantity, 0);
            let content_ids = cart.value.map((item) => item.product.vendor_code).join(",");
            let value = getTotalAfterDiscount();

            window.fbq("track", "InitiateCheckout", {
                source: "le-perle.com.ua",
                num_items: num_items.toString(),
                content_ids: [content_ids],
                content_type: "product",
                value: value,
                event_id: event_id,
                external_id: external_id,
                currency: "UAH",
            });

            const dataLayerItems = createDataLayerItems(cart.value);

            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            dataLayer.push({
                event: "begin_checkout",
                ecommerce: {
                    value: totalPrice.value,
                    currency: "UAH",
                    items: dataLayerItems,
                },
            });

            try {
                axios.post(apiUrl + "fbapi", {
                    action: "InitiateCheckout",
                    event_id: event_id,
                    value: value,
                    content_ids: [content_ids],
                    num_items: num_items.toString(),
                    fbc: getCookie("_fbc"),
                    fbp: getCookie("_fbp"),
                    external_id: external_id,
                    useragent: window.navigator.userAgent,
                    url: `${window.location.origin}${window.location.pathname}`,
                });
            } catch (error) {
                console.error(error);
            }
        };

        const addToCart = (product, quantity) => {
            // Если вариаций нет, добавляем товар в корзину
            let cart = localStorage.getItem("cart");
            if (cart) {
                cart = JSON.parse(cart);
            } else {
                cart = [];
            }

            const itemToAdd = {
                id: product.id,
                product_id: product.id,
                variant_id: null,
                quantity: quantity ?? 1,
                percent: product.percent ?? 0,
                price: product.price,
                product: product,
                variant: [],
                is_set: 0,
            };

            const existingProductIndex = cart.findIndex((item) => item.id === itemToAdd.id);
            if (existingProductIndex >= 0) {
                cart[existingProductIndex].quantity++;
            } else {
                cart.push(itemToAdd);
            }
            existingProductIndex;

            store.dispatch("updateCart", cart);

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    value: totalPrice.value,
                    currency: "UAH",
                    items: [
                        {
                            item_name: product.name,
                            item_id: product.vendor_code,
                            price: parseFloat(product?.price),
                            item_brand: "Le-perle",
                            item_category: "",
                            item_variant: product.id,
                            quantity: 1,
                        },
                    ],
                },
            });

            localStorage.setItem("cart", JSON.stringify(cart));
            document.dispatchEvent(new CustomEvent("cart-updated"));
            component.proxy.setMaxHeight();
            toast.success("<b>" + product.name + "</b> додано до замовлення!", {
                position: "top-right",
            });

            try {
                axios.post(apiUrl + "fbapi", {
                    action: "AddToCart",
                    event_id: event_id,
                    value: parseFloat(product?.price),
                    content_name: product.name,
                    content_ids: product.id,
                    quantity: 1,
                    fbc: getCookie("_fbc"),
                    fbp: getCookie("_fbp"),
                    external_id: external_id,
                    useragent: window.navigator.userAgent,
                    url: `${window.location.origin}${window.location.pathname}`,
                });
            } catch (error) {
                console.error(error);
            }
        };

        const excludeProductIds = (id) => {
            return productPackageIds.value.includes(id);
        };

        const calculatePrice = (item) => {
            if (item.is_set === 1) {
                let price = 0;
                Object.values(item.sets).forEach((itemSet) => {
                    price += parseFloat(itemSet.price * discountPrecentDefaut);
                });
                return toFixed(price);
            } else if (!excludeProductIds(item.product.id)) {
                let price = countProductsCart() >= 2 ? (item.variant.price_origin ? item.variant.price_origin : item.product.price_origin) : item.variant.price ? item.variant.price : item.product.price;
                let discount = countProductsCart() >= 2 ? discountPrecentAdditional : discountPrecentDefaut;
                price = item.variant.price_origin ? item.variant.price_origin * discount : item.product.price_origin * discount;
                return toFixed(price);
            } else if (item.percent && item.is_set === 0) {
                return toFixed(item.variant.price_origin ? (item.variant.price_origin * (100 - item.percent)) / 100 : (item.product.price_origin * (100 - item.percent)) / 100);
            } else {
                return toFixed(item.variant.price_origin ? item.variant.price_origin : item.product.price_origin);
            }
        };

        const calculateOldPrice = (item) => {
            if (item.is_set === 1) {
                let price = 0;
                Object.values(item.sets).forEach((itemSet) => {
                    price += parseFloat(itemSet.old_price);
                });
                return toFixed(price);
            }
            return item.variant.old_price ?? item.product.old_price;
        };

        onMounted(() => {
            updateCart();
            fbCheckout();
            document.addEventListener("cart-updated", updateCart);
        });

        onMounted(async () => {
            if (!window.KTracking) {
                window.KTracking = {
                    collectNonUniqueClicks: false,
                    multiDomain: false,
                    R_PATH: "https://kt.trafficjack.team/RC21XY",
                    P_PATH: "https://kt.trafficjack.team/50f7fac/postback",
                    listeners: [],
                    reportConversion: function () {
                        this.queued = arguments;
                    },
                    getSubId: function (fn) {
                        this.listeners.push(fn);
                    },
                    ready: function (fn) {
                        this.listeners.push(fn);
                    },
                };
            }

            (function () {
                var a = document.createElement("script");
                a.type = "application/javascript";
                a.async = true;
                a.src = "https://kt.trafficjack.team/js/k.min.js";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(a, s);
            })();

            const revenue = 110;
            const status = "sale";
            const tid = Math.floor(Math.random() * 1000000000);
            KTracking.reportConversion(revenue, status, { tid });
        });

        return {
            cart,
            removeItem,
            getTotalBeforeDiscount,
            getTotalAfterDiscount,
            getDiscount,
            filterOptionValues,
            updateQuantity,
            priceTotalActionOneToOne,
            addToCart,
            countProductsCart,
            productPackage,
            excludeProductIds,
            limitedProductsIds,
            getProductsLimitAdditionalsCart,
            calculatePrice,
            calculateOldPrice,
            getRemainingAmount,
            getProgressWidth,
        };
    },

    methods: {
        toggleAccordion() {
            this.activeIndex = this.activeIndex === 1 ? null : 1;
        },
        beforeEnter(el) {
            el.style.maxHeight = "0";
        },
        enter(el) {
            el.style.maxHeight = `${el.scrollHeight}px`;
        },
        beforeLeave(el) {
            el.style.maxHeight = `${el.scrollHeight}px`;
        },
        leave(el) {
            el.style.maxHeight = "0";
        },
        setMaxHeight() {
            this.$nextTick(() => {
                const contentEl = this.$refs.content;
                if (contentEl) {
                    contentEl.style.maxHeight = `${contentEl.scrollHeight}px`;
                }
            });
        },
        getOptionLabel(optionValues) {
            if (optionValues.option.name === "size") {
                return "Розмір";
            } else if (optionValues.option.name === "clasp") {
                return "Застібка";
            } else if (optionValues.option.name === "length") {
                return "Довжина";
            } else if (optionValues.option.name === "color") {
                return "Колір";
            }
            // Вернуть оригинальное значение, если нет соответствующего условия
            return optionValues.option.name;
        },

        transformWordToSingular(word) {
            const dictionary = {
                Браслети: " браслету",
                Кольє: " кольє",
            };
            return dictionary[word] || ""; // Вернёт изменённое слово или исходное, если его нет в словаре
        },

        getImageProduct(media) {
            return media.product.downloads[0] ? this.$uploadUrl + media.product.downloads[0].path : "https://api.le-perle.com.ua/assets/img/logo.svg";
        },
        updateAccordionHeight() {
            if (this.activeIndex === 1) {
                this.$nextTick(() => {
                    const content = this.$refs.content;
                    if (content) {
                        content.style.maxHeight = `${content.scrollHeight}px`;
                    }
                });
            }
        },
    },
    mounted() {
        this.setMaxHeight();
        // this.getProductPackage();
    },
};
</script>
<style scoped>
.cart-item-disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}

.cart__progresses {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 25px;
}

.cart__progress-block {
    margin-bottom: 0;
}

.cart-progress-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 2;
}
</style>
