<template>
    <div>
        <div class="roulette-container">
            <canvas ref="canvas" width="320" height="320"></canvas>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";

export default {
    name: "RouletteProductsComponent",
    props: {
        spinActive: {
            type: Boolean,
            default: false,
        },
        products: {
            type: Array,
            required: true,
            validator: (value) => {
                return value.every((item) => "id" in item && "name" in item);
            },
        },
    },
    setup(props, { emit }) {
        const canvas = ref(null);
        let ctx;
        const products = ref(props.products);
        const numSegments = 8;
        const arc = (2 * Math.PI) / numSegments;
        let startAngle = 0;
        let spinAngleStart;
        let spinTime = 0;
        let spinTimeTotal = 0;
        const spinTimeout = ref(null);
        const preloadedImage = ref(null); // Объект для предзагруженного изображения

        // Функция для предварительной загрузки изображения gift.svg
        const preloadGiftImage = () => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = require("../assets/img/gift.svg"); // Путь к вашему gift.svg
                img.onload = () => resolve(img);
                img.onerror = () => reject(new Error("Не удалось загрузить изображение gift.svg"));
            });
        };

        // Функция для рисования колеса
        const drawRouletteWheel = () => {
            if (canvas.value?.getContext && preloadedImage.value) {
                const outsideRadius = 160;
                const textRadius = outsideRadius * 0.75;
                const insideRadius = 0;

                ctx = canvas.value.getContext("2d");
                ctx.clearRect(0, 0, 320, 320);

                // Генерация цветов для сегментов
                const colors = generateColors(numSegments);

                ctx.strokeStyle = "#ffffff";
                ctx.lineWidth = 2;

                for (let i = 0; i < numSegments; i++) {
                    const angle = startAngle + i * arc;
                    ctx.fillStyle = colors[i];

                    // Рисуем сегмент
                    ctx.beginPath();
                    ctx.arc(160, 160, outsideRadius, angle, angle + arc, false);
                    ctx.arc(160, 160, insideRadius, angle + arc, angle, true);
                    ctx.stroke();
                    ctx.fill();

                    // Рисуем gift.svg из предзагруженного изображения
                    ctx.save();

                    // Позиционирование изображения
                    ctx.translate(160 + Math.cos(angle + arc / 2) * textRadius, 160 + Math.sin(angle + arc / 2) * textRadius);
                    ctx.rotate(angle + arc / 2 + Math.PI / 2);
                    ctx.fillStyle = "#fff";
                    ctx.beginPath();
                    ctx.arc(0, 0, 30, 0, 2 * Math.PI);
                    ctx.fill();
                    ctx.lineWidth = 5;
                    ctx.strokeStyle = "#fff";
                    ctx.stroke();
                    ctx.drawImage(preloadedImage.value, -25, -25, 50, 50); // Рисуем изображение (размер 50x50)
                    ctx.restore();
                }

                // (Опционально) Рисуем указатель
                drawPointer();
            }
        };

        // Функция для рисования указателя
        const drawPointer = () => {
            ctx.fillStyle = "#ff77dc00";
            ctx.beginPath();
            ctx.moveTo(160 - 10, 10);
            ctx.lineTo(160 + 10, 10);
            ctx.lineTo(160, 30);
            ctx.closePath();
            ctx.fill();
        };

        // Функция для генерации цветовой схемы
        const generateColors = (num) => {
            const colors = [];
            const baseColors = ["#fff", "#FF77DC", "#fff", "#FF77DC", "#fff", "#FF77DC", "#fff", "#FF77DC"];

            for (let i = 0; i < num; i++) {
                colors.push(baseColors[i % baseColors.length]);
            }
            return colors;
        };

        // Функция для запуска вращения
        const spin = () => {
            spinAngleStart = Math.random() * 10 + 10; // Случайное начальное ускорение
            spinTime = 0;
            spinTimeTotal = Math.random() * 3000 + 4000; // Время вращения от 4 до 7 секунд
            rotateWheel();
        };

        // Функция для вращения колеса
        const rotateWheel = () => {
            spinTime += 30;
            if (spinTime >= spinTimeTotal) {
                stopRotateWheel();
                return;
            }
            const spinAngle = spinAngleStart - easeOut(spinTime, 0, spinAngleStart, spinTimeTotal);
            startAngle += (spinAngle * Math.PI) / 180;
            drawRouletteWheel();
            spinTimeout.value = setTimeout(rotateWheel, 30);
        };

        // Функция для остановки колеса
        const stopRotateWheel = () => {
            clearTimeout(spinTimeout.value);
            const degrees = (startAngle * 180) / Math.PI + 90;
            const arcd = (arc * 180) / Math.PI;
            const index = Math.floor((360 - (degrees % 360)) / arcd) % numSegments;
            const selectedProduct = products.value[index];

            // Эмитируем событие с объектом выбранного товара
            emit("wheelStopped", selectedProduct);
        };

        // Функция для плавного замедления вращения
        const easeOut = (t, b, c, d) => {
            const ts = (t /= d) * t;
            const tc = ts * t;
            return b + c * (tc + -3 * ts + 3 * t);
        };

        // Функция для запуска колеса при изменении spinActive
        watch(
            () => props.spinActive,
            (newVal) => {
                if (newVal) {
                    spin();
                }
            }
        );

        onMounted(async () => {
            try {
                preloadedImage.value = await preloadGiftImage();
                drawRouletteWheel();
            } catch (error) {
                console.error("Ошибка загрузки изображения gift.svg:", error);
            }
        });

        return { canvas, spin };
    },
};
</script>

<style scoped>
.roulette-container canvas {
    width: 265px;
}
.roulette-container {
    border-radius: 100%;
    border: 20px solid #ff77dc;
    width: 335px;
    height: 335px;
    display: flex;
    padding: 15px;
    position: relative;
}

.roulette-container::before {
    content: url("../assets/img/arrow-roullete.svg");
    position: absolute;
    top: -90px;
    left: 50%;
    transform: translate(-50%, 0%);
}
</style>
