<template>
    <div class="popup popup-cart" :class="{ 'popup-show': show }">
        <div class="popup__wrapper">
            <div class="popup__content">
                <div class="cart">
                    <div class="cart__top-wrapper">
                        <div class="cart__top top-cart">
                            <div class="top-cart__title">Кошик</div>
                            <button type="button" class="popup__close _icon-close" @click="closePopup"></button>
                        </div>
                        <div class="cart__progresses">
                            <div class="cart__progress-block">
                                <div class="cart__progress"><span :style="{ width: getProgressWidth() }"></span></div>
                                <span>До безкоштовної доставки залишилось:</span>
                                <span>{{ getRemainingAmount() }} грн.</span>
                            </div>
                        </div>
                    </div>
                    <div class="cart__items-wrapper">
                        <div class="cart__items" v-for="item in cart" :key="item.id">
                            <div class="cart__item item-cart">
                                <div v-if="item?.gift && getAmoutFroFreeGift() !== 0" class="cart-item-disabled"></div>
                                <div v-if="item?.gift && getAmoutFroFreeGift() !== 0" class="cart__progress-block cart-progress-block">
                                    <div class="cart__progress"><span :style="{ width: getProgressWidthAmoutForFreeGift() }"></span></div>
                                    <span>До отримання подарунку залишилось:</span>
                                    <span>{{ getAmoutFroFreeGift() }} грн.</span>
                                </div>
                                <router-link :to="{ name: 'Product', params: { slug: item.product.slug } }" class="item-cart__image-ibg"><img :src="getImageProduct(item)" alt="" /></router-link>
                                <div class="item-cart__content">
                                    <div class="item-cart__info">
                                        <div class="item-cart__title">
                                            <router-link :to="{ name: 'Product', params: { slug: item.product.slug } }">{{ item.product.name.ua ?? item.product.name }}</router-link>
                                        </div>
                                        <div class="item-cart__material" v-for="optionValues in filterOptionValues(item.variant.option_values)" :key="optionValues.id">
                                            {{ getOptionLabel(optionValues) }}<span v-if="item.product.category">{{ transformWordToSingular(item.product.category.title.ua) }}</span
                                            >: {{ optionValues.value }}
                                        </div>

                                        <div class="item-cart__material" v-if="item.engraving">Гравіювання: {{ item.engraving }}</div>

                                        <Counter v-if="!item?.gift" v-model="item.quantity" class="item-cart__counter counter_small" :productIdLimited="getProductsLimitAdditionalsCart()" :productId="item.id" :countProducts="countProductsCart()" @update:modelValue="(newQuantity) => updateQuantity(item, newQuantity)" />
                                    </div>
                                    <div class="item-cart__price">
                                        <div class="item-cart__number">
                                            <!-- <span :v-="iiftem.variant.old_price || item.product.old_price" style="opacity: 0.5; text-decoration: line-through; transform: scale(0.9)"> {{ item.variant.old_price ? item.variant.old_price : item.product.old_price }} </span> -->
                                            <span v-if="(item.variant.old_price && item.variant.old_price !== null && item.variant.old_price !== '0.00') || (item.product.old_price && item.product.old_price !== null && item.product.old_price !== '0.00')" style="opacity: 0.5; text-decoration: line-through; transform: scale(0.9)"> {{ calculateOldPrice(item) }} грн. </span>
                                            <!-- Для скидки -10 и 15% -->
                                            <span> {{ calculatePrice(item) }} грн. </span>
                                            <!-- END Для скидки -10 и 15% -->
                                            <!-- Для одиночкой скидки -->
                                            <!-- <span> {{ item.price || item.variant.price || item.product.price }} грн. </span> -->
                                            <!-- END Для одиночкой скидки -->
                                            <!-- <span> {{ item.variant.price ? item.variant.price : item.product.price }} грн. </span> -->
                                            <!-- <span v-if="item.free" style="color: red; transform: scale(0.8)">1 шт / 0 грн</span> -->
                                        </div>
                                        <button class="item-cart__button _icon-delete" @click="removeItem(item.id)"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cart__order-bottom">
                        <div class="cart__order order-cart">
                            <!-- <div class="cart__progress-block">
                                <div class="cart__progress"><span :style="{ width: getItemsCartProgressWidth() }"></span></div>
                                <span>Прикраса у подарунок: </span>
                                <span>залишилось додати {{ getItemsCart() }} шт.</span>
                            </div> -->
                            <div class="order-cart__title">Ваше замовлення</div>
                            <div class="order-cart__line">
                                <span>Сума замовлення:</span>
                                <span>{{ $toFixed(getTotalBeforeDiscount(), 2) }} грн.</span>
                            </div>
                            <div class="order-cart__line">
                                <span>Знижка:</span>
                                <span>{{ getDiscount() }} грн.</span>
                            </div>
                            <div class="order-cart__all">
                                <span>Разом:</span>
                                <span>{{ getTotalAfterDiscount() }} грн.</span>
                            </div>
                        </div>
                        <div class="order-cart__buttons">
                            <a href="#" @click="closePopup" class="order-cart__button button button_line"> Продовжити покупки </a>
                            <router-link to="/order" @click="closePopup" class="order-cart__button button"> Перейти до оплати </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex";
import Counter from "./Counter.vue";
import { ref, onMounted, computed, getCurrentInstance } from "vue";

export default {
    name: "CartPopup",
    components: {
        Counter,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const { appContext } = getCurrentInstance();
        const toFixed = appContext.config.globalProperties.$toFixed;

        const store = useStore();
        const cart = ref([]);
        const productPackageIds = computed(() => store.getters.getProductPackageIds);
        const freeDeliveryThreshold = 2500;

        const priceTotalActionOneToOne = ref(0);

        const discountPrecentDefaut = appContext.config.globalProperties.$discountPrecentDefaut;
        const discountPrecentAdditional = appContext.config.globalProperties.$discountPrecentAdditional;

        const getProgressWidth = () => {
            const total = getTotalAfterDiscount();
            return total >= freeDeliveryThreshold ? "100%" : `${(total / freeDeliveryThreshold) * 100}%`;
        };

        const getProgressWidthAmoutForFreeGift = () => {
            const total = getTotalAfterDiscount();
            return total >= 1000 ? "100%" : `${(total / 1000) * 100}%`;
        };

        const getItemsCartProgressWidth = () => {
            const countProductsCart = cart.value.reduce((total, item) => {
                return total + item.quantity;
            }, 0);
            return countProductsCart >= 3 ? "100%" : `${(countProductsCart / 3) * 100}%`;
        };

        const getRemainingAmount = () => {
            const total = getTotalAfterDiscount();
            return total >= freeDeliveryThreshold ? 0 : Math.round(freeDeliveryThreshold - total);
        };
        const getAmoutFroFreeGift = () => {
            const total = getTotalAfterDiscount();
            return total >= 1000 ? 0 : Math.round(1000 - total);
        };

        const getItemsCart = () => {
            const countProductsCart = cart.value.reduce((total, item) => {
                return total + item.quantity;
            }, 0);
            return countProductsCart >= 3 ? 0 : 3 - countProductsCart;
        };

        const updateCart = () => {
            let storedCart = localStorage.getItem("cart");
            if (storedCart) {
                cart.value = JSON.parse(storedCart);
            }
        };

        const filterOptionValues = (optionValues) => {
            if (optionValues && Array.isArray(optionValues)) {
                return optionValues.filter((option) => option && option.value !== null && option.value !== undefined);
            }
            return []; // Возвращаем пустой массив, если optionValues undefined или пуст
        };

        const removeItem = (itemId) => {
            // Получаем данные из localStorage
            let cartItems = JSON.parse(localStorage.getItem("cart") || "[]");
            // Находим товар по itemId
            const itemToRemove = cartItems.find((item) => item.id === itemId);

            if (itemToRemove) {
                cart.value = cart.value.filter((item) => item.id !== itemId);
                localStorage.setItem("cart", JSON.stringify(cart.value));
            } else {
                console.error(`Товар с id ${itemId} не найден`);
            }
        };

        const getItemPriceBeforeDiscount = (item) => {
            let price = 0;
            if (item.is_set === 1) {
                Object.values(item.sets).forEach((itemSet) => {
                    price += parseFloat(itemSet.old_price);
                });
                return price;
            }
            // Проверяем наличие старой цены в варианте и основном продукте
            if (item.variant) {
                if (item.variant.old_price != null) {
                    price = parseFloat(item.variant.old_price);
                } else {
                    price = parseFloat(item.product.old_price || item.variant.price || item.product.price);
                }
            } else {
                price = parseFloat(item.product.old_price || item.product.price);
            }

            return price;
        };

        const getTotalBeforeDiscount = () => {
            return cart.value.reduce((total, item) => {
                return total + item.quantity * getItemPriceBeforeDiscount(item);
            }, 0);
        };

        const getItemPriceAfterDiscount = (item) => {
            if (item.is_set === 1) {
                return item.price;
            }
            if (item.price_origin) {
                return item.price_origin; // Если есть вариация и цена вариации, то используем её
            }
            if (item.variant && item.variant.price_origin) {
                return item.variant.price_origin; // Если есть вариация и цена вариации, то используем её
            }
            return item.product.price_origin; // Иначе используем цену продукта
        };

        const countProductsCart = () => {
            return cart.value.reduce((total, item) => {
                if (!productPackageIds.value.includes(item.product.id)) {
                    return total + item.quantity;
                }
                return total;
            }, 0);
        };

        function getProductsLimitAdditionalsCart() {
            return cart.value.filter((item) => item.product.tags && item.product.tags.some((tag) => tag.value === "limit_additions_to_cart")).map((item) => item.product.id);
        }

        const getTotalAfterDiscount = () => {
            let totalAfterDiscount = 0;
            let countProducts = countProductsCart();
            // Перебираем все товары в корзине
            cart.value.forEach((item) => {
                let itemTotal = item.quantity * getItemPriceAfterDiscount(item);

                // Применяем скидку 10%, если id товара не равен 2
                if (!productPackageIds.value.includes(item.product.id) && countProducts < 2 && item.is_set === 0) {
                    itemTotal *= discountPrecentDefaut;
                } else if (!productPackageIds.value.includes(item.product.id) && countProducts >= 2 && item.is_set === 0) {
                    itemTotal *= discountPrecentAdditional;
                } else if (item.percent && item.is_set === 0) {
                    itemTotal *= (100 - item.percent) / 100;
                }

                // Добавляем стоимость товара (уже со скидкой, если она применяется) к итоговой сумме
                totalAfterDiscount += itemTotal;
            });

            // Возвращаем итоговую сумму, округленную до двух знаков после запятой
            return parseFloat(toFixed(totalAfterDiscount));
        };

        const getDiscount = () => {
            let totalAfterDiscount = 0;
            let countProducts = countProductsCart();

            // Перебираем все товары в корзине и применяем скидку 10%, если это не товар с id == 2
            cart.value.forEach((item) => {
                let itemTotal = item.quantity * getItemPriceAfterDiscount(item);

                // Применяем скидку 10%, если id товара не равен 2
                if (!productPackageIds.value.includes(item.product.id) && countProducts < 2) {
                    itemTotal *= discountPrecentDefaut;
                } else if (!productPackageIds.value.includes(item.product.id) && countProducts >= 2) {
                    itemTotal *= discountPrecentAdditional;
                } else if (item.percent && item.is_set === 0) {
                    itemTotal *= (100 - item.percent) / 100;
                }

                totalAfterDiscount += itemTotal;
            });

            // Вычисляем скидку как разницу между ценой до скидок и после
            let discount = getTotalBeforeDiscount() - totalAfterDiscount;

            // Округляем результат до двух знаков после запятой и возвращаем
            return Number(toFixed(discount));
        };
        const updateQuantity = (item, newQuantity) => {
            let updatedItem = { ...item, quantity: newQuantity };
            let index = cart.value.findIndex((i) => i.id === item.id);
            cart.value.splice(index, 1, updatedItem);
            localStorage.setItem("cart", JSON.stringify(cart.value));
        };

        const excludeProductIds = (id) => {
            return productPackageIds.value.includes(id);
        };

        const calculatePrice = (item) => {
            if (item.is_set === 1) {
                let price = 0;
                Object.values(item.sets).forEach((itemSet) => {
                    price += parseFloat(itemSet.price);
                });
                return toFixed(price);
            } else if (!excludeProductIds(item.product.id)) {
                let price = countProductsCart() >= 2 ? (item.variant.price_origin ? item.variant.price_origin : item.product.price_origin) : item.variant.price ? item.variant.price : item.product.price;
                let discount = countProductsCart() >= 2 ? discountPrecentAdditional : discountPrecentDefaut;
                price = item.variant.price_origin ? item.variant.price_origin * discount : item.product.price_origin * discount;

                return toFixed(price);
            } else if (item.percent && item.is_set === 0) {
                return toFixed(item.variant.price_origin ? (item.variant.price_origin * (100 - item.percent)) / 100 : (item.product.price_origin * (100 - item.percent)) / 100);
            } else {
                return toFixed(item.variant.price_origin ? item.variant.price_origin : item.product.price_origin);
            }
        };

        const calculateOldPrice = (item) => {
            if (item.is_set === 1) {
                let price = 0;
                Object.values(item.sets).forEach((itemSet) => {
                    price += parseFloat(itemSet.old_price);
                });
                return toFixed(price);
            }
            return item.variant.old_price ?? item.product.old_price;
        };

        onMounted(() => {
            updateCart();
            document.addEventListener("cart-updated", updateCart);
        });

        return {
            cart,
            removeItem,
            getTotalBeforeDiscount,
            getTotalAfterDiscount,
            getDiscount,
            filterOptionValues,
            updateQuantity,
            getProgressWidth,
            getRemainingAmount,
            getItemsCart,
            getItemsCartProgressWidth,
            priceTotalActionOneToOne,
            countProductsCart,
            excludeProductIds,
            getProductsLimitAdditionalsCart,
            calculatePrice,
            calculateOldPrice,
            getAmoutFroFreeGift,
            getProgressWidthAmoutForFreeGift,
        };
    },

    methods: {
        closePopup() {
            this.$store.commit("closeCart");
        },
        getOptionLabel(optionValues) {
            if (optionValues.option.name === "size") {
                return "Розмір";
            } else if (optionValues.option.name === "clasp") {
                return "Застібка";
            } else if (optionValues.option.name === "length") {
                return "Довжина";
            } else if (optionValues.option.name === "color") {
                return "Колір";
            }
            // Вернуть оригинальное значение, если нет соответствующего условия
            return optionValues.option.name;
        },
        transformWordToSingular(word) {
            const dictionary = {
                Браслети: " браслету",
                Кольє: " кольє",
            };
            return dictionary[word] || ""; // Вернёт изменённое слово или исходное, если его нет в словаре
        },
        getImageProduct(media) {
            return media.product.downloads[0] ? this.$uploadUrl + media.product.downloads[0].path : "https://api.le-perle.com.ua/assets/img/logo.svg";
        },
    },
    mounted() {},
};
</script>

<style scoped>
.cart-item-disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}

.cart__progresses {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 25px;
}

.cart__progress-block {
    margin-bottom: 0;
}

.cart-progress-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 2;
}
</style>
